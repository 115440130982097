import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { UploadOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Row,
  Col,
  Card,
  Input,
  Upload,
  Button,
  DatePicker,
  SelectProps,
  Select,
  Alert,
  InputNumber,
  UploadFile,
} from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import config from '../../../config';
import useUploadField from '../../../hooks/useUploadField';
import CardTitle from '../../../components/cardTitle/cardTitle';
import { getCurrencies } from './getCurrencies';
import styles from './index.module.css';
import useInvoiceForm from '../../../hooks/useInvoiceForm';
import { InvoiceInterface } from '../../../models/invoiceInterface';
import { getCompanies } from '../../../services/services';
import { getFileIcon } from '../../../utils/getFileIcon';
import { useUserContext } from '../../../context/user.context';
import { truncateText } from '../../../utils/utils';

export interface FieldError {
  name: Array<string | number>;
  errors: string[];
  warnings: string[];
}

const InvoiceForm = ({
  initValues,
  invoiceId,
}: {
  initValues?: InvoiceInterface;
  invoiceId?: string;
}): ReactElement => {
  const { beforeUploadDoc } = useUploadField();
  const { t } = useTranslation('common');

  const { user } = useUserContext();

  const { form, handleFormChange, formValues, handleSubmit, loading } = useInvoiceForm({
    initValues,
    invoiceId,
  });

  const currencies = useMemo((): SelectProps['options'] => {
    return getCurrencies();
  }, []);

  const [companies, setCompanies] = useState<SelectProps['options']>([]);

  const apiCompanies = useCallback(async (): Promise<void> => {
    const members = await getCompanies({ limit: 99999 });
    const companiesOptions: SelectProps['options'] = members?.items
      .filter((member) => {
        return member.id !== user.id;
      })
      .map((member) => ({
        label: member.companyName,
        value: member.id,
      }));
    setCompanies(companiesOptions);
  }, [user]);

  useEffect((): void => {
    apiCompanies();
  }, [apiCompanies]);

  const infoMessage = useMemo((): string => {
    const mapObj: any = {
      mimes: config.acceptedFileFormats.docsFormats,
      fileSize: config.limits.maxDocFileSize,
      maxFiles: config.limits.maxInvoiceAdditionalFiles,
    };
    return t('messages.invoiceFilesInfo').replace(
      /\b(?:mimes|fileSize|maxFiles)\b/gi,
      (matched) => mapObj[matched],
    );
  }, [t]);

  const addonCurrency: ReactElement = (
    <Form.Item
      name='currency'
      noStyle
      rules={[
        {
          required: true,
          message: t('messages.requiredField') ?? '',
        },
      ]}
    >
      <Select showSearch className={styles.currencySelect} options={currencies} />
    </Form.Item>
  );

  const showAddDocumentsButton = useMemo((): boolean => {
    if (formValues.documents === undefined || formValues.documents === null) return true;
    if (
      formValues.documents?.fileList !== undefined &&
      formValues.documents?.fileList?.length < config.limits.maxInvoiceAdditionalFiles
    )
      return true;
    return false;
  }, [formValues.documents]);

  const renderDocLine = (
    originNode: ReactElement,
    file: UploadFile,
    _fileList: UploadFile[],
    actions: { remove: () => void },
  ): ReactElement => {
    return (
      <Card size='small' key={file.uid} className={styles.docCard}>
        <div className={styles.innerDocCard}>
          <div className={styles.iconAndName}>
            <div className={styles.fileIcon}>{getFileIcon(file.name)}</div>
            <div className={styles.fileName}>{truncateText(file.name, 20)}</div>
          </div>
          <Button
            type='ghost'
            icon={<DeleteOutlined />}
            onClick={actions.remove}
            className={styles.fileRemove}
          />
        </div>
      </Card>
    );
  };

  const disableFutureDates = useCallback((current: dayjs.Dayjs | null): boolean => {
    if (!config.features.displayInvoicesForMotion) return false;
    return current ? current > dayjs() : false;
  }, []);

  return (
    <div>
      <Form
        form={form}
        initialValues={formValues}
        onValuesChange={handleFormChange}
        onFinish={handleSubmit}
      >
        <Row gutter={50}>
          <Col xs={24} lg={10}>
            <Card>
              <div className={styles.innerFilesCard}>
                <CardTitle title={t('misc.invoiceFile')} type='bold' />
                <Form.Item
                  name='invoice'
                  valuePropName='invoice'
                  rules={[
                    {
                      required: true,
                      message: t('messages.requiredField') ?? '',
                    },
                  ]}
                >
                  <Upload
                    maxCount={1}
                    multiple={false}
                    accept={config.acceptedFileFormats.docsFormats}
                    beforeUpload={beforeUploadDoc}
                    showUploadList={true}
                    itemRender={renderDocLine}
                    defaultFileList={[...(formValues.invoice?.fileList ?? [])]}
                  >
                    {(formValues.invoice === undefined ||
                      formValues.invoice?.fileList?.length === 0) && (
                      <Button icon={<UploadOutlined />}>{t('buttons.upload')}</Button>
                    )}
                  </Upload>
                </Form.Item>
                <CardTitle title={t('misc.additionalDocs')} type='bold' />
                <Form.Item name='documents' valuePropName='list'>
                  <Upload
                    multiple={true}
                    accept={config.acceptedFileFormats.docsFormats}
                    beforeUpload={beforeUploadDoc}
                    showUploadList={true}
                    itemRender={renderDocLine}
                    defaultFileList={[...(formValues.documents?.fileList ?? [])]}
                    maxCount={config.limits.maxInvoiceAdditionalFiles}
                  >
                    {showAddDocumentsButton && (
                      <Button icon={<PlusOutlined />}>
                        {config.features.displayInvoicesForMotion
                          ? t('buttons.addBillOfLading')
                          : t('buttons.addNewDoc')}
                      </Button>
                    )}
                  </Upload>
                </Form.Item>
                <Alert
                  description={infoMessage}
                  type='info'
                  showIcon
                  className={styles.filesInfo}
                />
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={14} className={styles.invRightSection}>
            <Card>
              <Form.Item
                label={t('misc.invoiceName')}
                name='name'
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredField') ?? '',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('misc.addressedTo')}
                name='clientId'
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredField') ?? '',
                  },
                ]}
              >
                <Select
                  showSearch
                  options={companies}
                  filterOption={(inputValue, option): boolean =>
                    String(option?.label).toLowerCase().includes(inputValue.toLowerCase())
                  }
                />
              </Form.Item>
              <Form.Item
                label={t('misc.value')}
                name='value'
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredField') ?? '',
                  },
                ]}
              >
                <InputNumber className={styles.valueInput} addonBefore={addonCurrency} />
              </Form.Item>
              <Form.Item
                label={t('misc.issuedDate')}
                name='issuedDate'
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredField') ?? '',
                  },
                ]}
              >
                <DatePicker
                  format={config.dateFormat.universalFormat}
                  disabledDate={disableFutureDates}
                />
              </Form.Item>
              <Form.Item
                label={t('misc.dueDate')}
                name='dueDate'
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredField') ?? '',
                  },
                ]}
              >
                <DatePicker
                  format={config.dateFormat.universalFormat}
                  disabledDate={disableFutureDates}
                />
              </Form.Item>
              <Button type='primary' htmlType='submit' size='large' loading={loading}>
                {t('buttons.save')}
              </Button>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InvoiceForm;
